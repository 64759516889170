.skills-container{
    /* margin-top: 10px; */
    text-align: center ;
    max-width:100%;
    /* margin: auto; */
    background: rgba(0, 0, 0, 0.911);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 56%, 0 100%);
    clip-path: polygon(0 0, 100% 12%, 100% 85%, 0 100%);
    padding: 100px 0 0 0;
    min-height: 1100px;
    height: 100%;
    
}
.skills-title{
    font-size: 70px;
    padding-bottom: 10px;
    margin: 50px auto;
    border-bottom: 1px solid var(--primary-color);
    width: 50%;
    color: white;
}
.skills-title span{
    color: var(--primary-color);
 
}
.skill-list{
   display: flex;
   flex-flow: row wrap;
   margin-top: 20px;
   justify-content: center;
}
.skill-icon-warp{
    height: 100px;
    width: 100px;
    margin: 25px;
    position: relative;
    cursor: pointer;
    border-radius: 100%;
    flex: 1;
    flex-basis: 20%;
    flex: 0 1 calc(20% - 8px)
}
.skill-icon{
    height: 100%;
    /* width: 100%; */
    background-position: no-repeat center center/cover;
    
}
.skill-icon-warp :not(img){
    color: var(--primary-color);
    opacity: 0;
    transition: 1.5s;
}
.skill-icon-warp:hover :not(img){
    opacity: 1;
}
@media(max-width: 740){
    .skills-title{
        display: inline;
        padding-top: 50px;
    }
}
@media only screen and (max-width: 657px){
    .skills-title{
        display: inline;
        padding-top: 50px;
    }
    .skills-container {
        /* min-height: 1350px; */
        padding-top: 200px;
    }
}

@media only screen and (max-width: 839px){
    .skills-title{
        display: inline;
        padding-top: 50px;
    }
    .skills-container {
        /* min-height: 1350px; */
        padding-top: 200px;
    }
    .skill-icon-warp {
        height: 50px;
        width: 50px;
    }

    .skill-text {
        font-size: 20px;
    }
}
@media only screen and (max-width: 541px){

    .skills-container {
        min-height: 1100px;
    }
}
@media(max-width: 436px){
   
    .skills-container {
        /* min-height: 1750px; */
    }
}

@media(max-width: 376px){
    .skills-title{
        display: inline;

    }
    .skills-container {
        padding-top: 300px;
        padding-bottom: 30px;
        /* min-height: 1500px; */
    }
}


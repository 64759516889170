.contact-me-container{
    max-width: 78%;
    margin: auto;
    margin-top: -75px;
    height: 400px;
}
.contact-title{
    font-size: 60px;
    width: 50%;
    color: var(--primary-color);
    text-align: center;
    margin: 60px auto 20px auto;
    border-bottom: 1px solid var(--primary-color);
}
.contact-title span{
    color:black;
}

.contact-me{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-me img {
    height: 200px;
    width: 400px;
}

.icons{
    /* margin-left: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section1{
    display: inline-block;
}

.section2{
    display: flex;
    flex-direction: row;
}

.section2 i,.section1 i{
    margin: 10px;
    cursor: pointer;
}
.section1 i{
    color: var(--primary-color);
}
.facebook,.linkedin{
    color: var(--primary-color);
}
.btn{
   text-align: center;
   margin-top: 30px;
}
.email-text {
    font-size: 16px;
}
.contact-btn{
    border-radius: 10px;
    outline: none;
    padding: 5px;
    margin: 14px;
    background-color: rgb(27, 27, 27);
    color: white;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
    width: 130px;
    align-self: center;
}
.email-icon{
    margin-left: 3px !important;
}

@media(max-width: 850px){
    .contact-me{
        display: flex;
        flex-direction: column;
    }
    img,.icons{
        align-self: center;
    }
    .contact-title{
        width: 80%; 
    }
    
}

@media(max-width: 500px){
    .contact-me-container{
        height: 480px;
    }
    .contact-me img {
        height: 150px;
        width: 350px;
    }
    .section2{
        display: flex;
        flex-direction: column;
    }
    
}

@media(max-width: 320px){

    .email {
        font-size: 30px;
    }

    .email-text {
        font-size: 12px;
    }
    .city {
        font-size: 12px;
    }
    .location {
        font-size: 30px;
    }
}

.intro-wrapper {

    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.911);
    width: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 56%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 78%, 0 100%);
    height: 750px;
    padding: 160px 60px 60px 60px;
    /* position: absolute; */
    overflow: hidden;
    justify-content: center;
}
.intro-img {
    
    border-radius: 10px;
    width: 500px;
    align-self: self-start;
    /* margin-left: 30%; */
    z-index: 10000;
}

.intro-titles {
    display: flex;
    flex-direction: column;
    padding: 120px 0 0 15px;
}
.intro-titles-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 600px;
    visibility: hidden;
}
.title {
    color: white;
}
.intro-subtitle {
    background-color: var(--primary-color);
    border-radius: 5px;
    align-self: start;
    text-align: right;
    position: relative;
    left: -2rem;
    font-size: x-large;
    width: calc(100% + 1.5em);
    z-index: -1;
    padding: 5px;
}
.box div{
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: 6px solid rgba(255, 255, 255, 0.8);
}
.box div:nth-child(1){
    top: 12%;
    left: 42%;
    animation: animate 10s linear infinite;
}
.box div:nth-child(2){
    top: 80%;
    left: 50%;
    animation: animate 9s linear infinite;
}
.box div:nth-child(3){
    top: 17%;
    left: 6%;
    animation: animate 7s linear infinite;
}
.box div:nth-child(4){
    top: 20%;
    left: 60%;
    animation: animate 10s linear infinite;
}
.box div:nth-child(5){
    top: 67%;
    left: 10%;
    animation: animate 6s linear infinite;
}
.box div:nth-child(6){
    top: 80%;
    left: 70%;
    animation: animate 12s linear infinite;
}
.box div:nth-child(7){
    top: 60%;
    left: 80%;
    animation: animate 15s linear infinite;
}
.box div:nth-child(8){
    top: 32%;
    left: 25%;
    animation: animate 16s linear infinite;
}
.box div:nth-child(9){
    top: 90%;
    left: 25%;
    animation: animate 9s linear infinite;
}
.box div:nth-child(10){
    top: 20%;
    left: 80%;
    animation: animate 5s linear infinite;
}
@keyframes animate{
    0%{
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }
    100%{
        transform: scale(1.3) translateY(-90px) rotate(360deg);
        opacity: 0;
    }
}

/* responsive rulls*/
@media(max-width: 960px){
    .intro-img{
        width: 450px;
        /* margin-left: 20%; */
    }
    .intro-titles{
        padding: 115px 0 0 15px;
    }
}
@media(max-width: 810px){
    .intro-img{
        width: 400px;
        /* margin-left: 10%;  */
    }
    .intro-titles{
        padding: 115px 0 0 15px;
    }
     /* .intro-subtitle{
        font-size: 2vh;
    } */
    .title{
     /* align-self: center; */
     /* font-size: 3vh; */
    }
}
@media(max-width: 716px){
    .intro-img{
        width: 375px;
    }
    .intro-titles{
        padding: 115px 0 0 15px;
    }
    .intro-subtitle{
        font-size: 2.5vh;
    } 
    .title{
     /* align-self: center; */
     font-size: 3vh;
     margin-left: -10px;
    }
}

.job-title {
    text-align: center;
}
@media(max-width: 688px){
    .intro-img{
        width: 400px;
        margin-left: 15%;
    }
    .intro-titles{
        padding: 115px 0 0 15px;
        visibility: hidden;
    }
    .intro-titles-mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 450px;
        visibility: visible;
        color: white;
        border-width: 3px;
        border-style: solid;
        border-image: 
          linear-gradient(
            to bottom, 
            white, 
            rgba(0, 0, 0, 0)
          ) 1 100%;
        align-items: center;
        width: 200px;
        height: 100px;
        padding: 20px 0 0 0;
    }
}
@media(max-width: 626px){
    #intro{
        margin-top: 60px;
    }
    .intro-img{
        width: 350px;
        margin-left: 15%; 
    }
    .intro-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 200px;
    }
    .intro-titles{
        padding: 70px 0 0 15px;
    }
    .intro-subtitle{
        margin-left: 12px;
        font-size: 1vh;
    }
    .title{
     align-self: center;
     font-size: 2vh;
    }
    .intro-titles-mobile {
        top: 500px;
        width: 200px;
        height: 100px;
        padding: 20px 0 0 0;
    }
}
@media(max-width: 470px){
    /* .intro-titles{
        padding: 70px 0 0 15px;
    } */
    .intro-img{
        margin-left: 23%;
        width: 340px;
    }
    .intro-titles{
        padding: 60px 0 0 15px;
    }
    .intro-subtitle{
        margin-left: 13px;
        font-size: 1vh;
    }
    .title{
     align-self: center;
     font-size: 1.5vh;
    }
    .intro-titles-mobile {
        top: 500px;
        width: 200px;
        height: 100px;
        padding: 20px 0 0 0;
    }
}
@media(max-width: 470px){
    /* .intro-titles{
        padding: 70px 0 0 15px;
    } */
    .intro-img{
        margin-left: 20%;
        width: 340px;
    }
    /* .intro-titles-mobile {
        top: 500px;
        width: 200px;
        height: 100px;
        padding: 20px 0 0 0;
    } */
}
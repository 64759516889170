*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Bebas Neue';
}

body{
    --primary-color:rgb(60, 63, 61);
    scrollbar-width: thin;
}

::-webkit-scrollbar { width: 10px; height: 3px;}
::-webkit-scrollbar-button:start {  background-color: black; height: 55px; }
::-webkit-scrollbar-track-piece { background-color: white;}
::-webkit-scrollbar-thumb { 
    background-color: rgba(0, 0, 0, 0.911);
    border-radius: 10px;
    border: 2px solid white;
}
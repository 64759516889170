.about-me-container{
    text-align: center;
    padding: 0 60px 60px 60px;
    
}
.about-me-container h1{
    font-size: 70px;
    width: 40%;
    padding-bottom: 10px;
    margin: 20px auto;
    border-bottom: 1px solid var(--primary-color);

}
.about-me-container h1 span{
    color: var(--primary-color);
}

.about-me-flex{
  display: flex;
  justify-content: center;
  /* margin: 10px 70px; */

}

.about-me-content{
    align-self: center;
    line-height: 35px;
    font-size: 22px;
}

@media(max-width:850px){
    .about-me-container,.about-me-flex{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .about-me-container{
        padding: 0 30px 30px 30px;
        margin-bottom: -50px;
    }
    .on-me-content{
        margin-bottom: 30px;
    }
    .about-me-container h1{
        width: auto;
    }
}

@media(max-width:1200px){
    .about-me-content{
        font-size: 18px;
    }
}

@media(max-width:700px){
    .about-me-container{
        padding: 0 30px 30px 30px;
        margin-bottom: -80px;
    }
    .about-me-content{
        font-size: 14px;
    }
}
@media(max-width:350px){
    .about-me-content{
        font-size: 12px;
    }
    
}
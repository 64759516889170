.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: white;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(48,46,46,1) 5%, rgba(119,130,133,1) 60%, rgba(0,0,0,1) 100%);
    box-shadow: 0 0 10px rgb(36, 36, 36);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    font-weight: bold;
    padding: 10px;
    z-index: 10;
    height: 55px;
    font-size: large;
    align-items: center;
    justify-content: space-between;
}

.home{
    /* position: absolute; */
    /* left: -2px; */
    cursor: pointer;
    width: 35px;
}
.link{
    margin-right: 25px;
    cursor: pointer;
    color: white;
    font-family: 'Bebas Neue';
    font-size: 20px;
}
.link-left {
    left: -2px;
    margin-right: 25px;
    cursor: pointer;
    color: white;
    font-family: 'Bebas Neue';
    font-size: 20px;
}

/* responsive rulls*/

@media(max-width: 600px){
    .link{
        margin-right: 7px;
    }
}

@media(max-width: 500px){
    .home{
        position: relative;
        left: 0;
    }
    .link{
        display: none;
    }
    .nav-container{
        justify-content: center;
    }

}